
@import '../assets/styles/colors.scss';
@import '../assets/styles/constants.scss';

.document-structure-tree {
  .edit-coversheet-icon {
    .icon-button {
      padding-left: 0px;
    }
  }
}

