

p span:first-of-type {
  flex-shrink: 0;
  min-width: 50px;
}

p.block:not(.last-block) {
  margin-bottom: 17px;
}

p.block {
  margin-bottom: 2px;
}

