
@import 'src/assets/styles/document.scss';

table {
  table-layout: fixed;
}

.logo {
  text-align: right;
}

.logo .logo-details {
  max-width: 265px;
  max-height: 115px;
  text-decoration: none;
}

.client-reference-number {
  text-align: left;
}

.date {
  text-align: left;
}

.client-sign {
  height: 3cm;
  text-align: left;
}

.patent-application {
  letter-spacing: 3pt;
  text-align: center;
  font-weight: bold;
}

.draft {
  height: 3.5cm;
  letter-spacing: 3pt;
  text-align: center;
  font-weight: bold;
}

.patent-proprietor-type {
  height: 2cm;
  text-align: left;
}

.patent-proprietor {
  height: 4.5cm;
  text-align: left;
}

.with-the-designation {
  height: 2.5cm;
  text-align: left;
}

.generic-term {
  text-align: center;
  height: 6cm;
  font-weight: bold;
}

.coversheet {
  font-size: 12px;
  font-family: Arial, sans-serif;
  position: relative;
  padding: 15px;
}

.coversheet table {
  width: 100%;
  empty-cells: show;
  border-collapse: collapse;
  border-spacing: 0;
}

.coversheet tr {
  page-break-inside: avoid;
}

.coversheet td {
  padding: 0px;
  border: none;
  font-size: 1.25em;
  vertical-align: top;
}
