
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.search-bar {
  text-align: left;
  width: 100%;

  .navbar {
    height: fit-content;
    min-height: inherit;
    align-items: stretch;
    display: flex;

    .navbar-brand {
      min-height: initial;
      display: none;
    }

    .navbar-menu {
      padding: 0;
      align-items: stretch;
      -webkit-box-align: stretch;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      -webkit-box-flex: 1;

      .navbar-start {
        justify-content: flex-start;
        margin-right: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-box-align: stretch;

        input {
          margin: 3px 6px 6px;
          height: 26px;
          width: 278px;

          &:enabled:hover, &:enabled:focus {
            margin-right: 5px;
          }
        }

        .button {
          border: none;
          background-color: transparent;
          height: $search-bar-height;

          &:hover, &:focus {
            background-color: transparent;
          }

          &:hover:enabled {
            color: $pengine-blue-dark;
          }

          &.search-button:after {
            content: "";
            background: $pengine-grey-dark;
            position: absolute;
            bottom: 4px;
            right: 0;
            height: 75%;
            width: 1px;
          }

          &.search-button {
            padding-left: 12px;
            padding-right: 19px;
          }

          &.replace-button {
            padding-left: 17px;
            padding-right: 10px;
          }

          .exi {
            display: inline-block;
          }

          .button-icon, .button-label {
            float: left;
          }

          .button-label {
            padding-left: 8px;
            font-family: $text-font;
            font-size: 14px;
          }
        }
      }

      .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-box-align: stretch;

        .navbar-item {
          padding: 0;

          .close-button {
            padding-top: 0px;
            padding-bottom: 0px;
            background-color: transparent;
            height: $search-bar-height;
            border: none;

            i {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
  }

  .buttons {
    margin-top: auto;
    margin-bottom: 0px;
  }
}
