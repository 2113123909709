// Global color variables
$pengine-grey-dark-dark: rgb(79, 79, 79);
$pengine-grey-dark: rgb(135, 135, 135);
$pengine-grey: rgb(201, 201, 201);
$pengine-grey-light-hover: rgb(213, 213, 213);
$pengine-grey-light: rgb(225, 225, 225);
$pengine-grey-light-light-hover: rgb(241, 241, 241);
$pengine-grey-light-light: rgb(248, 248, 248);

$pengine-orange-dark-dark: rgb(235, 100, 30);
$pengine-orange-dark: rgb(240, 130, 60);
$pengine-orange: rgb(245, 155, 105);
$pengine-orange-light: rgb(250, 175, 140);
$pengine-orange-light-light: rgb(253, 216, 198);

// Extra colors (exceptionial)
$pengine-blue-dark-dark: rgb(0, 85, 160);
$pengine-blue-dark: rgb(0, 130, 200);
$pengine-blue: rgb(100, 165, 220);
$pengine-blue-light: rgb(150, 200, 240);

// Colors we added
$pengine-red: red;
$pengine-delete-red: #b30505;
$pengine-delete-red-light: #bf3b3b;
$pengine-editor-odd-block: rgb(222, 222, 222);
$pengine-editor-even-block: rgb(238, 238, 238);
$pengine-editor-find: #ffeac2;
$pengine-editor-find-current: #ffde00;
$pengine-document-structure-tree-empty-block: rgb(175, 175, 175);

// Reference signs
$pengine-reference-sign: rgb(141, 87, 59);
$pengine-warn-yellow: #f6c45d;

// Spellcheck error colors
$pengine-spellcheck-confused-words: #f2b24c;
$pengine-spellcheck-punctuation: #f2b24c;
$pengine-spellcheck-hilfestellung-kommasetzung: #f2b24c;
$pengine-spellcheck-grammar: #f2b24c;
$pengine-spellcheck-misc: #f2b24c;
$pengine-spellcheck-semantics: #f2b24c;
$pengine-spellcheck-casing: #f2b24c;
$pengine-spellcheck-compounding: #f2b24c;
$pengine-spellcheck-typography: #f2b24c;
$pengine-spellcheck-typos: #eb5757;
$pengine-spellcheck-redundancy: #5c4cff;
$pengine-spellcheck-correspondence: #5c4cff;
$pengine-spellcheck-style: #5c4cff;
$pengine-spellcheck-colloquialisms: #5c4cff;
$pengine-spellcheck-empfohlene-rechtschreibung: #5c4cff;
$pengine-spellcheck-unknown: purple;

// Ai-generated texts
$pengine-ai-generated: #007fff;
$pengine-ai-generated-disabled: #4a90e2;
