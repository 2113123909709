

.canvas-container {

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  overflow-y: hidden;
  overflow-x: auto;

  #figureEditorCanvas {
    width: 100%;
    height: 100%;
  }
}

.canvas-drop {
  width: fit-content;
  height: fit-content;
}
