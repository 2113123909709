
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.header-customized {
  margin-top: 0px;
}

.title-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.rating-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-grow: 0;
  justify-content: right;

  &.is-disabled {
    :nth-child(n) {
      color: #F3F3F3;
      cursor: not-allowed;
    }
  }
}

.rating-text-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: right;
}

.toggle-button-customized {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.is-black-costomized {
  color: #000000;
  background-color: #FFFFFF;
}

.has-0-bottom-padding {
  padding-bottom: 0px !important;
}

.has-blue-selection {
  ::selection {
    color: #FFFFFF;
    background: #0d68ce !important;
  }
}

.has-small-bottom-padding {
  padding-bottom: 3px !important;
}

.is-black-costomized-textarea {
  ::selection {
    background: #0d68ce !important;
  }

  ::v-deep {
    ::selection {
      background: #0d68ce !important;
    }
  }

  user-select: text !important;
  -ms-user-select: text !important;
  height: 150px;
  color: #0a0a0a;
  overflow-y: auto;
  font-size: 16px;
  resize: vertical;
  width: 100%;

  padding-top: 3px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-bottom: 3px !important;

  margin-top: 3px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-bottom: 3px !important;

  border: 1px solid #4f4f4f !important;

  &:not(.is-disabled):hover, &:not(.is-disabled)::selection {
    padding-top: 2px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;

    margin-top: 3px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    margin-bottom: 3px !important;
  }
}

.output-textarea {
  border: 1px solid #4f4f4f !important;
  padding: 5px;
  font-size: 16px;

  ::v-deep {
    h1 {
      font-size: 16px;
      font-weight: bold !important;
    }

    h3 {
      font-weight: bold !important;
    }

    strong {
      font-weight: bold !important;
    }

    p {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    li {
      padding-left: 10px;
    }

    ol {
    }

    a {
      color: #0d68ce;
      text-decoration-line: underline;
      text-decoration-color: #0d68ce;
    }
  }

  .strong {
    font-weight: normal !important;
  }

  .h3:hover {
    font-weight: bold !important;
  }

  p {
    padding-left: 20px;
    padding-top: 20px;
    text-indent: 50px;
  }

  overflow-y: auto;
  overflow-x: auto;
  resize: vertical;
  width: 100%;
  height: 150px;
}

.is-black-costomized-dropdown {
  color: #0a0a0a;
  font-size: 16px;
  height: 25px;
  width: 100%;
  padding: 2px !important;
  text-indent: 2px !important;
  border: 1px solid #4f4f4f !important;
}

.is-black-costomized-dropdown:hover {
  padding: 1px !important;
  text-indent: 2px !important;
  border: 2px solid #4f4f4f !important;
}

.close-button-customized-parent {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.close-button-customized {
  width: 12px;
  height: 12px;
  margin-right: 0px;
  padding-right: 0px;
}

.ai-template-doropdown-parent {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.ai-template-dropdown {
  opacity: 2000;
  overflow: visible;

  ::selection {
    background-color: #4f4f4f !important;
    background: #4f4f4f !important;
  }
}

.ai-assistant-dialog {
  position: absolute;
  z-index: 1200;
  opacity: 1;
  visibility: visible;

  .ai-assistant-dialog-child {
    ::v-deep {
      overflow: auto;
    }

    resize: horizontal;
    height: 100%;
    margin-top: 5px;
    text-align: left;
    background-color: white;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    z-index: 1201;

    .headline {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      cursor: move;
    }
  }
}
