
@import "src/assets/styles/colors";

.application-figure {
  .modal-dialog {

    &-dialog {
      min-width: 400px;
      min-height: 220px;
      width: auto;
      height: auto;

      .modal-dialog-header {
        flex-direction: row;
        justify-content: flex-end;

        .icon-button {
          margin-top: 0;
          margin-right: 0;
          padding: 0;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .header-message {
          width: 100%;
          font-weight: bold;
          font-size: small;
          margin: 2rem 0 0 1rem;
          display: flex;

          .warning-sign-wrapper {
            width: 18px;
            height: 18px;
            margin: 0 5px;

            i {
              background-color: $pengine-warn-yellow;
            }
          }
        }
      }
    }

    &-body {
      display: flex;
      flex: 1;

      img {
        max-width: 80vw;
        max-height: 80vh;
        object-fit: contain;
      }

      i {
        display: block;
        margin: auto auto;
      }
    }
  }
}
