
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.header {
  width: 100%;
  min-width: 1280px;
  height: $header-height !important;

  &:not(.header-without-border) {
    .header-main {
      .top-container-in-header {
        border-bottom-color: $pengine-grey-dark-dark;
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }
    }
  }

  .header-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: column-reverse;

    .header-bar-container .dropdown:not(.is-active) {
      z-index: 31;
    }

    // Let them appear in front of dialogs
    .top-container-in-header {
      background-color: white;
      z-index: 30;
    }

    .menu-button-container {
      float: left;
      padding-top: 22px;
      padding-left: 16px;
      padding-right: 112px; // To take the same space like the back button

      .sidebar-button-icon {
        height: 30px;
        width: 30px;
        margin-top: -6px;
      }
    }

    .top-container-in-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-aligned-header-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .back-link {
          display: flex;
          height: fit-content;


          .back-link-content {
            width: $header-back-button-width;
            height: $header-height - 1px;
            padding: 22px;

            &:hover {
              background-color: $pengine-grey-light-light;
            }
          }

          a {
            color: black;
            font-weight: normal;
          }
        }

        .back-button-icon {
          float: left;
          height: 18px;
          width: 18px;
        }

        .back-button-text {
          float: left;
          margin-top: -4px;
          margin-left: 6px;
          font-size: $font-size-header;
          color: $text-color;
        }
      }
    }

    .logo-and-user-container {
      display: flex;
      justify-self: flex-end;
      flex-flow: row nowrap;
    }
  }
}


.header-with-pinned-bar {
  // height: ($header-height + 50px) !important;
  height: auto !important;
}

