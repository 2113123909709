
@import 'src/assets/styles/colors';

.logo-container {
  .logo {
    padding-top: 8px;
    padding-right: 24px;
    height: 54px;
    cursor: default;
  }
}

a.dropdown-item {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
}

a.dropdown-item:hover {
  color: $pengine-blue-dark;
}
