

#zoomWrapper {
  margin: 30px auto;
  position: relative;
  width: fit-content;
  height: fit-content;

  #zoomTarget {
    transform-origin: top left;
    width: fit-content;
    height: fit-content;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

