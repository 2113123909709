@import './colors.scss';
@import './constants.scss';

// Icons (exi = eXXcellent solution icon - powered by Nucleo)
$icon-path: '../icons/';
$icon-size: 18px;
$md-icon-size: 24px;
$lg-icon-size: 50px;

.exi-plus {
  -webkit-mask-image: url($icon-path + 'add.svg');
  mask-image: url($icon-path + 'add.svg');
}

.ai-feature-button {
  -webkit-mask-image: url($icon-path + 'ai-feature-button.svg');
  mask-image: url($icon-path + 'ai-feature-button.svg');
}

.exi-ai-generated {
  -webkit-mask-image: url($icon-path + 'ai-generated.svg');
  mask-image: url($icon-path + 'ai-generated.svg');
}

.exi-ai-apply-and-remove {
  -webkit-mask-image: url($icon-path + 'ai-apply-and-remove.svg');
  mask-image: url($icon-path + 'ai-apply-and-remove.svg');
}

.exi-ai-apply {
  -webkit-mask-image: url($icon-path + 'check-circle-2.svg');
  mask-image: url($icon-path + 'check-circle-2.svg');
}

.exi-ai-remove {
  -webkit-mask-image: url($icon-path + 'remove-circle.svg');
  mask-image: url($icon-path + 'remove-circle.svg');
}

.exi-pen {
  -webkit-mask-image: url($icon-path + 'pencil.svg');
  mask-image: url($icon-path + 'pencil.svg');
}

.exi-face {
  -webkit-mask-image: url($icon-path + 'account.svg');
  mask-image: url($icon-path + 'account.svg');
}

.exi-arrow-left {
  -webkit-mask-image: url($icon-path + 'arrow-left.svg');
  mask-image: url($icon-path + 'arrow-left.svg');
}

.exi-chevron-left {
  -webkit-mask-image: url($icon-path + 'chevron-left.svg');
  mask-image: url($icon-path + 'chevron-left.svg');
}

.exi-chevron-right {
  -webkit-mask-image: url($icon-path + 'chevron-right.svg');
  mask-image: url($icon-path + 'chevron-right.svg');
}

.exi-tree-node-collapsed {
  -webkit-mask-image: url($icon-path + 'triangle-right.svg');
  mask-image: url($icon-path + 'triangle-right.svg');
}

.exi-triangle-left {
  -webkit-mask-image: url($icon-path + 'triangle-left.svg');
  mask-image: url($icon-path + 'triangle-left.svg');
}

.exi-tree-node-expanded {
  -webkit-mask-image: url($icon-path + 'triangle-down.svg');
  mask-image: url($icon-path + 'triangle-down.svg');
}

.exi-calendar {
  -webkit-mask-image: url($icon-path + 'calendar.svg');
  mask-image: url($icon-path + 'calendar.svg');
}

.exi-download {
  -webkit-mask-image: url($icon-path + 'download.svg');
  mask-image: url($icon-path + 'download.svg');
}

.exi-upload {
  -webkit-mask-image: url($icon-path + 'upload.svg');
  mask-image: url($icon-path + 'upload.svg');
}

.exi-close {
  -webkit-mask-image: url($icon-path + 'close.svg');
  mask-image: url($icon-path + 'close.svg');
}

.exi-small-spinner-unmasked {
  background-image: url($icon-path + 'dark-loading.svg');
  background-color: transparent !important;
  background-size: 18px 18px;
}

.exi-large-spinner-unmasked {
  background-image: url($icon-path + 'light-loading.svg');
  background-color: transparent !important;
  background-size: 50px 50px;
}

.exi-undo {
  -webkit-mask-image: url($icon-path + 'undo.svg');
  mask-image: url($icon-path + 'undo.svg');
}

.exi-redo {
  -webkit-mask-image: url($icon-path + 'redo.svg');
  mask-image: url($icon-path + 'redo.svg');
}

.exi-reference {
  -webkit-mask-image: url($icon-path + 'checklist.svg');
  mask-image: url($icon-path + 'checklist.svg');
}

.exi-apply-changes {
  -webkit-mask-image: url($icon-path + 'arrow-right.svg');
  mask-image: url($icon-path + 'arrow-right.svg');
}

.exi-info {
  -webkit-mask-image: url($icon-path + 'information.svg');
  mask-image: url($icon-path + 'information.svg');
}

.exi-view {
  -webkit-mask-image: url($icon-path + 'view.svg');
  mask-image: url($icon-path + 'view.svg');
}

.exi-delete {
  -webkit-mask-image: url($icon-path + 'bin.svg');
  mask-image: url($icon-path + 'bin.svg');
}

.exi-add-reference-sign {
  -webkit-mask-image: url($icon-path + 'link.svg');
  mask-image: url($icon-path + 'link.svg');
}

.exi-search {
  -webkit-mask-image: url($icon-path + 'search.svg');
  mask-image: url($icon-path + 'search.svg');
}

.exi-favorite-filled {
  -webkit-mask-image: url($icon-path + 'favorite-filled.svg');
  mask-image: url($icon-path + 'favorite-filled.svg');
}

.exi-favorite-empty {
  -webkit-mask-image: url($icon-path + 'favorite-empty.svg');
  mask-image: url($icon-path + 'favorite-empty.svg');
}

.exi-add-library {
  -webkit-mask-image: url($icon-path + 'book.svg');
  mask-image: url($icon-path + 'book.svg');
}

.exi-synchronize {
  -webkit-mask-image: url($icon-path + 'synchronize.svg');
  mask-image: url($icon-path + 'synchronize.svg');
}

.exi-landscape {
  -webkit-mask-image: url($icon-path + 'landscape.svg');
  mask-image: url($icon-path + 'landscape.svg');
}

.exi-portrait {
  -webkit-mask-image: url($icon-path + 'portrait.svg');
  mask-image: url($icon-path + 'portrait.svg');
}

.exi-menu {
  -webkit-mask-image: url($icon-path + 'menu.svg');
  mask-image: url($icon-path + 'menu.svg');
}

.exi-gears {
  -webkit-mask-image: url($icon-path + 'gears.svg');
  mask-image: url($icon-path + 'gears.svg');
}

.exi-calendar-check {
  -webkit-mask-image: url($icon-path + 'calendar-check.svg');
  mask-image: url($icon-path + 'calendar-check.svg');
}

.exi-warning-sign {
  -webkit-mask-image: url($icon-path + 'warning-sign.svg');
  mask-image: url($icon-path + 'warning-sign.svg');
}

.exi-warning-sign-outlined {
  -webkit-mask-image: url($icon-path + 'report-problem-triangle.svg');
  mask-image: url($icon-path + 'report-problem-triangle.svg');
}

.exi-report-problem {
  -webkit-mask-image: url($icon-path + 'exx-report-problem-circle.svg');
  mask-image: url($icon-path + 'exx-report-problem-circle.svg');
}

.exi-report-problem-empty {
  -webkit-mask-image: url($icon-path + 'exx-report-problem-circle-empty.svg');
  mask-image: url($icon-path + 'exx-report-problem-circle-empty.svg');
}

.exi-chevron-up {
  -webkit-mask-image: url($icon-path + 'arrow-up-8.svg');
  mask-image: url($icon-path + 'arrow-up-8.svg');
}

.exi-chevron-down {
  -webkit-mask-image: url($icon-path + 'arrow-down-8.svg');
  mask-image: url($icon-path + 'arrow-down-8.svg');
}

.exi-copy {
  -webkit-mask-image: url($icon-path + 'copy-3.svg');
  mask-image: url($icon-path + 'copy-3.svg');
}

.exi-copy-thick {
  -webkit-mask-image: url($icon-path + 'copy_thick.svg');
  mask-image: url($icon-path + 'copy_thick.svg');
}

.exi-copy-application {
  -webkit-mask-image: url($icon-path + 'clone_data_frame.svg');
  mask-image: url($icon-path + 'clone_data_frame.svg');
}

.exi-paste {
  -webkit-mask-image: url($icon-path + 'copy-paste.svg');
  mask-image: url($icon-path + 'copy-paste.svg');
}

.exi-patent-engine-logo {
  -webkit-mask-image: url('../../../public/logo.svg');
  mask-image: url('../../../public/logo.svg');
}

.exi-license {
  -webkit-mask-image: url($icon-path + 'license.svg');
  mask-image: url($icon-path + 'license.svg');
}


.exi-user-info {
  -webkit-mask-image: url($icon-path + 'user-info.svg');
  mask-image: url($icon-path + 'user-info.svg');
}

.exi-logout {
  -webkit-mask-image: url($icon-path + 'logout.svg');
  mask-image: url($icon-path + 'logout.svg');
}

.exi-pin {
  -webkit-mask-image: url($icon-path + 'pin-1.svg');
  mask-image: url($icon-path + 'pin-1.svg');
}

.exi-magic-wand {
  -webkit-mask-image: url($icon-path + 'magic-wand-2.svg');
  mask-image: url($icon-path + 'magic-wand-2.svg');
}

.exi-man {
  -webkit-mask-image: url($icon-path + 'gingerbread-man.svg');
  mask-image: url($icon-path + 'gingerbread-man.svg');
}

.exi-check {
  -webkit-mask-image: url($icon-path + 'check-2.svg');
  mask-image: url($icon-path + 'check-2.svg');
}

.exi-check-circle {
  -webkit-mask-image: url($icon-path + 'check-circle-1.svg');
  mask-image: url($icon-path + 'check-circle-1.svg');
}

.exi-book {
  -webkit-mask-image: url($icon-path + 'book-2.svg');
  mask-image: url($icon-path + 'book-2.svg');
}

.exi-floppy {
  -webkit-mask-image: url($icon-path + 'floppy-disk.svg');
  mask-image: url($icon-path + 'floppy-disk.svg');
}

.exi-versions {
  -webkit-mask-image: url($icon-path + 'synchronize-time.svg');
  mask-image: url($icon-path + 'synchronize-time.svg');
}

.exi-lock-open {
  -webkit-mask-image: url($icon-path + 'lock-open-1.svg');
  mask-image: url($icon-path + 'lock-open-1.svg');
}

.exi-spellcheck {
  -webkit-mask-image: url($icon-path + 'spelling-check-2.svg');
  mask-image: url($icon-path + 'spelling-check-2.svg');
}

.exi-paragraph-spacing {
  -webkit-mask-image: url($icon-path + 'paragraph-spacing.svg');
  mask-image: url($icon-path + 'paragraph-spacing.svg');
}

.exi-align-justify {
  -webkit-mask-image: url($icon-path + 'align-justify.svg');
  mask-image: url($icon-path + 'align-justify.svg');
}

.exi-zoom-area-in {
  -webkit-mask-image: url($icon-path + 'zoom-area-in.svg');
  mask-image: url($icon-path + 'zoom-area-in.svg');
}

.exi-zoom-in {
  -webkit-mask-image: url($icon-path + 'zoom-in.svg');
  mask-image: url($icon-path + 'zoom-in.svg');
}

.exi-zoom-out {
  -webkit-mask-image: url($icon-path + 'zoom-out.svg');
  mask-image: url($icon-path + 'zoom-out.svg');
}

.exi-zoom-100 {
  -webkit-mask-image: url($icon-path + 'zoom-100.svg');
  mask-image: url($icon-path + 'zoom-100.svg');
}

.exi-zoom-doc-100 {
  -webkit-mask-image: url($icon-path + 'zoom-doc-100.svg');
  mask-image: url($icon-path + 'zoom-doc-100.svg');
}

.exi-ignore {
  -webkit-mask-image: url($icon-path + 'file-delete-2.svg');
  mask-image: url($icon-path + 'file-delete-2.svg');
}

.exi-dictionary {
  -webkit-mask-image: url($icon-path + 'notebook-bookmarked.svg');
  mask-image: url($icon-path + 'notebook-bookmarked.svg');
}

.exi-refresh {
  -webkit-mask-image: url($icon-path + 'refresh.svg');
  mask-image: url($icon-path + 'refresh.svg');
}

.exi-scaleFull {
  -webkit-mask-image: url($icon-path + 'scaleFull.svg');
  mask-image: url($icon-path + 'scaleFull.svg');
}

.exi-scaleContentArea {
  -webkit-mask-image: url($icon-path + 'scaleContentArea.svg');
  mask-image: url($icon-path + 'scaleContentArea.svg');
}

.exi-toggleHelpLines {
  -webkit-mask-image: url($icon-path + 'toggleHelpLines.svg');
  mask-image: url($icon-path + 'toggleHelpLines.svg');
}

.exi-editor-no-split {
  -webkit-mask-image: url($icon-path + 'editor-no-split.svg');
  mask-image: url($icon-path + 'editor-no-split.svg');
}

.exi-editor-horizontal-split {
  -webkit-mask-image: url($icon-path + 'editor-horizontal-split.svg');
  mask-image: url($icon-path + 'editor-horizontal-split.svg');
}

.exi-editor-vertical-split {
  -webkit-mask-image: url($icon-path + 'editor-vertical-split.svg');
  mask-image: url($icon-path + 'editor-vertical-split.svg');
}

.exi-window-reduce-right {
  -webkit-mask-image: url($icon-path + 'window-reduce-right.svg');
  mask-image: url($icon-path + 'window-reduce-right.svg');
}

.exi-application-view-hide-figureeditor {
  -webkit-mask-image: url($icon-path + 'application-view-hide-figureeditor.svg');
  mask-image: url($icon-path + 'application-view-hide-figureeditor.svg');
}

.exi-application-view-show-figureeditor {
  -webkit-mask-image: url($icon-path + 'application-view-show-figureeditor.svg');
  mask-image: url($icon-path + 'application-view-show-figureeditor.svg');
}

.exi {
  content: '';
  display: block;
  height: $icon-size;
  width: $icon-size;
  background-color: $text-color;
  -webkit-mask-size: cover;
  mask-size: cover;
}
.exi-autofill-button {
  content: '';
  display: block;
  height: 27px;
  width: 27px;
  background-color: $text-color;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.exi-md {
  @extend .exi;
  height: $md-icon-size;
  width: $md-icon-size;
}

.exi-lg {
  @extend .exi;
  height: $lg-icon-size;
  width: $lg-icon-size;
}

// All delete buttons should be dark red on hover
button:hover:not([disabled]) /*, button:focus*/
{
  .exi-delete {
    background-color: $pengine-delete-red !important;
  }
}
