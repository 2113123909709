
.cover-sheet {
  .modal-dialog {
    $dialog-width: 1200px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-footer {
      width: $dialog-width;
      padding-top: 12px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }

    input.is-small {
      height: 30px;
      font-size: 16px !important;
    }

    .icon.is-small {
      height: 30px !important;
      margin: 0px;
    }

    span.is-small select {
      padding-top: 1px !important;
    }

    span.is-small select:hover {
      text-indent: 0px;
    }

  }
}

.document-structure-node-selected .exi-pen:not(:hover) {
  background-color: white !important;
}
