
@import 'src/assets/styles/colors.scss';

// Remove button border in any case
button.dropdown-button {
  border: 0 solid transparent !important;

  &:hover:enabled, &:focus:enabled {
    border: 0 solid transparent !important;
  }
}

.apply-ai-generated-text {
  color: $pengine-grey-dark-dark;

  &:hover {
    color: $pengine-blue-dark !important;
  }
}

.apply-and-remove-ai-parent {
  display: flex
}

.apply-and-remove-ai {
  width: 16px;
  height: 16px;
  margin: 4px 10px 0 0;
}

.horizontal-divider {
  margin: 3px 10px;
}
