
@import 'src/assets/styles/colors';

.about {
  .modal-dialog {
    &-dialog {

      .modal-dialog-header {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0 0 20px 0;

        .icon-button {
          margin-top: 0px;
          margin-right: 0px;
          padding: 0px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .modal-dialog-body {
        align-items: center;
        text-align: left;

        #patent-engine-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 300px;
        }

        #version {
          font-size: 16px;
          margin-top: -6px;
        }


        #design-to-ip-container {
          display: flex;
          flex-flow: column wrap;
          justify-content: start;
          align-items: start;

          width: 32%; // so that the copyright fits in one line

          #design-to-ip-logo {
            width: 85%;
          }

          #copyright {
            color: $pengine-grey-dark;
            width: 100%;
            font-size: 60%;
          }
        }

        #about-dialogue-content {
          .content {
            font-weight: normal;
          }

          .about-title {
            padding-bottom: 5px;
            padding-top: 25px;
            text-transform: uppercase;
            font-size: 16px;
          }

          #address, #support-email {
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }

      }
    }
  }
}

