
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.symbol-list {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: visible;

  .symbol-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-flow: row nowrap;
    padding-left: 5px;
    border-bottom: 1px solid $pengine-grey;

    h2 {
      padding-left: 12px;
    }
  }

  .table-container {
    overflow: auto;
  }

  /* Styling for the read-only draggable symbols */
  .symbol {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    height: $row-height;
    color: $pengine-grey-dark-dark;
    font-size: $font-size-normal;
    align-items: center;
    text-align: center;

    .symbol-label {
      padding-left: $reference-sign-label-padding-left;
      min-width: $reference-sign-label-width;
    }

    .symbol-name {
      padding-left: $reference-sign-name-padding-left;
      max-width: $reference-sign-name-max-width;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .symbol-drag-image {
    background-color: white;
    padding: .5em 1em;

    .symbol-drag-image-label {
      color: $pengine-orange-dark-dark;
    }
  }
}
