
@import 'src/assets/styles/constants';

.search {
  .b-tabs {
    width: 100%;
    margin-bottom: 0px !important;

    .tab-content {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    > nav ul {
      // width: fit-content;
    }
  }

  .field {
    // min-width: 430px;
    margin-top: 8px;
    $searchButtonSize: 27px;

    .taginput {
      margin-left: 6px;
      width: 105%;

      .taginput-container {
        min-height: 35px;
        padding-right: 14px !important;

        // The first tag should save some space for the search button on the left
        .tag:first-of-type {
          margin-left: $searchButtonSize;
        }

        .tag:not(body) {
          background-color: $pengine-blue-dark !important;
          color: white;
          padding-top: 1px;

          a {
            &:before, &:after {
              background-color: white;
            }
          }
        }

        .autocomplete {

          .control {
            input {
              padding-top: 1px;
              padding-left: 4px !important;
              font-size: $font-size-input;
            }

            .icon {
              width: 24px;
              height: 24px;
              font-size: 22px;
            }
          }
        }
      }

      .taginput-container {
        border: 1px solid #FFFFFF !important;

        &:hover:enabled, &:focus:enabled {
          border: 1px solid #FFFFFF !important;
        }
        .autocomplete {
          .control {
            input.is-empty {
              padding-left: $searchButtonSize !important;
            }
          }
        }
      }
    }

    .search-button {
      height: 35px;
      padding-top: 1px;
      margin-right: -8px - $searchButtonSize;
      z-index: 5; // Stay in front of the input field (even on hover)
    }

    .delete-all-button {
      height: 35px;
      margin-right: -9px;
      margin-top: 0px;
      margin-left: -26px;
      padding-right: 11px;
      z-index: 5; // Stay in front of the input field (even on hover)
      .exi {
        height: 13px;
        width: 13px;
        // padding-right: 4px;
      }

      &-dummy {
        width: 19px;
      }
    }
  }

  .search-space-tabs {
    margin: 5px auto 0px auto;
    width: fit-content;

    .tabs ul {
      border: none;
    }

    .tabs li {
      a {
        $tab-a-padding-horizontal: 7px;
        padding-left: $tab-a-padding-horizontal;
        padding-right: $tab-a-padding-horizontal;
        margin-bottom: 5px;
      }
    }
  }
}
