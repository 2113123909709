
.figure {
  .figure-main-splitpanes {
    > div:nth-child(1) {
      border-left: none !important;
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}
