
@import 'src/assets/styles/constants';

.cookies-banner {
  .modal-dialog {
    $dialog-width: 1000px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-header {
      width: $dialog-width;
    }

    &-body {
      width: $dialog-width;

      .cookie-message {
        text-align: justify;
        font-size: $font-size-normal;
        padding-bottom: 30px;
        opacity: 1;

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }

    &-footer {
      width: $dialog-width;
      padding-top: 12px;
      padding-bottom: 16px;

      .cookie-options {
        padding-bottom: 30px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        white-space: nowrap;

        .control-label:hover {
          color: white;
        }
      }

      .cookie-buttons {
        padding-right: 5px;

        button {
          width: 156px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
