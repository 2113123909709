@import './colors.scss';
@import './constants.scss';

// Symbols (exi = eXXcellent solution icon - powered by Nucleo)
$symbol-path: '../symbols/';
$symbol-width: 45px;
$symbol-height: 28px;
$md-symbol-height: 20px;
$help-line-color: #687196;

.exs-arrow {
  -webkit-mask-image: url($symbol-path + 'arrow.svg');
  mask-image: url($symbol-path + 'arrow.svg');
}

.exs-bottom-open-brace {
  -webkit-mask-image: url($symbol-path + 'bottomOpenBrace.svg');
  mask-image: url($symbol-path + 'bottomOpenBrace.svg');
}

.exs-brace {
  -webkit-mask-image: url($symbol-path + 'brace.svg');
  mask-image: url($symbol-path + 'brace.svg');
}

.exs-curve {
  -webkit-mask-image: url($symbol-path + 'curve.svg');
  mask-image: url($symbol-path + 'curve.svg');
}

.exs-dashed-arrow {
  -webkit-mask-image: url($symbol-path + 'dashedArrow.svg');
  mask-image: url($symbol-path + 'dashedArrow.svg');
}

.exs-dashed-curve {
  -webkit-mask-image: url($symbol-path + 'dashedCurve.svg');
  mask-image: url($symbol-path + 'dashedCurve.svg');
}

.exs-dashed-line {
  -webkit-mask-image: url($symbol-path + 'dashedLine.svg');
  mask-image: url($symbol-path + 'dashedLine.svg');
}

.exs-draw-curve {
  -webkit-mask-image: url($symbol-path + 'drawCurve.svg');
  mask-image: url($symbol-path + 'drawCurve.svg');
}

.exs-draw-dashed-curve {
  -webkit-mask-image: url($symbol-path + 'drawDashedCurve.svg');
  mask-image: url($symbol-path + 'drawDashedCurve.svg');
}

.exs-line {
  -webkit-mask-image: url($symbol-path + 'line.svg');
  mask-image: url($symbol-path + 'line.svg');
}

.exs-top-open-brace {
  -webkit-mask-image: url($symbol-path + 'topOpenBrace.svg');
  mask-image: url($symbol-path + 'topOpenBrace.svg');
}

.exs-horizontal-help-line {
  -webkit-mask-image: url($symbol-path + 'horizontalHelpLine.svg');
  mask-image: url($symbol-path + 'horizontalHelpLine.svg');
  background-color: $help-line-color !important;
}

.exs-vertical-help-line {
  -webkit-mask-image: url($symbol-path + 'verticalHelpLine.svg');
  mask-image: url($symbol-path + 'verticalHelpLine.svg');
  background-color: $help-line-color !important;
}

.exs {
  content: '';
  display: block;
  height: $symbol-height;
  width: $symbol-width;
  background-color: $text-color;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.exs-md {
  @extend .exs;
  height: $md-symbol-height;
  width: $symbol-width;
}
.exs-big {
  content: '';
  display: block;
  height: 42px;
  width: 42px;
  background-color: $text-color;
  -webkit-mask-size: cover;
  mask-size: cover;
  border-color: #FFFFFF;
}