
@import 'src/assets/styles/constants';

.applicationDocument {
  font-family: $editor-font;
}

.ProseMirror {
  white-space: break-spaces;
}

.ProseMirror:focus {
  outline: none;
}
