
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

@mixin editable-taginput-styles {

  ::placeholder {
    color: $pengine-grey-dark;
  }

  .taginput-container {
    .tag:not(body).tag-style-manual {

      background-color: $pengine-blue-dark !important;
      color: white;

      a {
        &:before, &:after {
          background-color: white;
        }
      }
    }

    .autocomplete {
      padding-left: 0;

      .control {
        input {
          padding-left: 24px !important;
        }

        .icon {
          width: 24px;
          height: 24px;
          font-size: 22px;
        }
      }
    }
  }
}


.tag {
  //Width of the Tag itself
  //Adjustment in terms of tag size need to be made here. Inside the ellipsis-class will cause distortion
  max-width: calc(100% - 6px);


  .has-ellipsis {
    //Has to be set to maximum since the default value is 10em
    max-width: 100%;
  }
}


.reference-sign {
  .modal-dialog {
    &-dialog {
      min-width: 400px;
      max-height: 400px;

      .modal-dialog-header {
        position: absolute;
        right: 0px;
        width: inherit;
        flex-flow: row-reverse;
      }

      .modal-dialog-body {
        padding-top: 20px;
        padding-bottom: 20px;

        .label {
          font: $text-font;
          font-size: $font-size-label;
          font-weight: 400;
          padding-left: 0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
          width: auto;
        }

        .icon-word-stem-refresh {
          margin-top: 1px;
        }

        .exi { // eXXcellent Icons
          display: inline-block;
          // padding-top: 2px;
          margin-bottom: -3px;
        }

        .field {
          padding-right: 0px;
        }

        .taginput-container {
          min-height: 62px;
          align-items: normal !important;
        }

        .exclude-stem-form-matches {
          @include editable-taginput-styles;
        }

        .stem-form-matches {
          .taginput-container {
            cursor: auto;

            input {
              cursor: auto;
            }

            input {
              padding-left: 7px !important;
            }
            .tag:not(body).tag-style-warn {
              background-color: $pengine-warn-yellow !important;
            }
          }
        }

        .stem-forms {
          .taginput {
            .counter {
              overflow: visible;
              height: 0px;
            }
          }
          @include editable-taginput-styles;
        }
      }
    }
  }
}
