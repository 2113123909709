@import './colors.scss';    // Color variables
@import './constants.scss'; // Other variables

// Global datepicker styling
.datepicker {
  margin-top: 1px;

  input {
    height: $input-height;
    padding-left: 25px !important;
    padding-top: 3px;
    padding-bottom: 1px;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius !important;
    color: $text-color;
    font: $input-font !important;
  }
  input:enabled:hover, input:focus {
    padding-left: 24px !important;
    box-shadow: none;
  }
  .exi { // eXXcellent Icons
    height: 12px;
    width: 12px;
    background-color: $pengine-orange-dark-dark;
  }
  .control .icon {
    margin-top: -5px;
    .exi { // eXXcellent Icons
      background-color: $text-color;
    }
  }
  .control.has-icons-left .icon {
    height: 2.5em;
  }

  .dropdown-content {
    padding-top: 0px;
    padding-bottom: 0px;

    .dropdown-item {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .dropdown.is-bottom-left .dropdown-menu {
    top: -10px;
    right: -4px;

    .datepicker-header {
      height: 28px;
      padding-top: 0px;
      border-bottom: none;
      margin-bottom: 4px;

      .has-text-primary {
        color: $pengine-orange-dark-dark !important;
      }

      select, a {
        height: 23px;
      }
      select {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 3.5px;
        border-width: 1px;
      }
      select, .pagination-previous, .pagination-next, .pagination-link {
        border-color: $pengine-grey-dark;

        &:hover, &:focus {
          border-color: $pengine-grey-dark;
        }
      }

      .select:not(.is-multiple):not(.is-loading)::after {
        border-color: $pengine-orange-dark-dark;
      }
      .select:not(.is-multiple) {
        height: 2.5em;
      }
      select:enabled:hover, select:focus {
        border-width: 1px !important;
        margin-left: 0px;
        padding-left: 8px !important;
        box-shadow: none;
      }
    }
    .datepicker-content {
      .datepicker-cell {
        padding: 6px 10px;
      }
      .datepicker-cell.is-selected {
        background-color: $pengine-orange-dark-dark;
      }
      .datepicker-cell.is-today {
        border: solid 1px $pengine-orange;
      }
    }
  }
}
