
@import 'src/assets/styles/constants';

.application-figure-list {
  width: inherit;
  height: 100%;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;

  .header-button {
    display: flex;
    float: right;
  }


  .application-figure-header {
    width: inherit;
    height: $subheader-height;
    min-height: $subheader-height;
    padding-left: 5px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;

    -webkit-user-select: none;
    user-select: none;
    border-bottom: 1px solid $pengine-grey;

    h2 {
      flex-shrink: 0;
      margin-top: 0;
      padding-left: 12px;
      margin-bottom: 0px;
    }

    .header-buttongroup {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon-button {
        display: flex;
      }

      .add-button {
        padding-left: 12px;
        padding-right: 12px;
      }

      .loading-button {
        padding-left: 12px;
        padding-right: 12px;
      }

      .search-button {
        padding-left: 12px;
        padding-right: 0px;
      }
    }
  }

  .destination-position {
    td {
      box-shadow: inset 0px 10px 0px -7px $pengine-orange !important;
    }
  }

  .selected-figure {
    td {
      background-color: $pengine-orange-light-light !important;
    }
  }

  .table-container {
    overflow-y: auto;
    height: calc(100% - 50px);

    .table {
      border: transparent !important;
      height: 100%;

      tr {
        background-color: transparent;
        min-height: $figure-size;
        height: $figure-size;

        td {
          height: 100%;
          padding: 0px;
        }

        td:first-child {
          width: $figure-size;
        }

        td:last-child {
          min-width: 140px;
          max-width: 200px;
        }

        // The last entry is just a hack to make it possible to drag to the end of the list
        &:not(:first-child):last-child {
          height: 4px;

          .figure-image, .figure-list-item {
            display: none;
          }
        }
      }

      tr:not(.is-empty):hover {
        background-color: $pengine-grey-light-light !important;
      }
    }
  }
}
