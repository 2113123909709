@import './colors.scss';

$header-height: 62px;
$header-back-button-width: 320px;
$header-logo-user-width: 357px;
$header-bar-nav-buttons-height: 32px;
$subheader-height: 50px;
$header-transition-duration: 700ms;
$vertical-separator-input-padding-top: 50px;
$footer-hight: 50px;
$search-bar-height: 32px;

// == Text ==
$editor-font: Arial, Avenir, Helvetica, sans-serif;
$text-font: Avenir, Helvetica, Arial, sans-serif;
$text-color: $pengine-grey-dark-dark;
$text-color-disabled: $pengine-grey;
$editor-text-color-disabled: $pengine-grey-dark;
$error-color: $pengine-red;

$font-size-normal: 14px;
$font-size-modal: 16px;
$font-size-header: 18px;
$font-size-subheader: 16px;
$font-size-input: 13.5px;
$font-size-small: 12px;
$font-size-h1: 21px;
$font-size-label: $font-size-normal;
$font-size-tab: $font-size-normal;
$font-size-required: x-small; // Little text next to a required field

// == Input fields (also apllies partly to textareas and selects)
$input-font: 400 $font-size-input $text-font;
$input-height: 20px;
$input-border-radius: 0px;
$input-border-color: $pengine-grey-dark;
$input-border-hover-color: $pengine-grey-dark;
$input-border-focus-color: $pengine-grey-dark;

// == Dialog ==
$dialog-border-radius: 0px;

// == Popover ==
$popover-background-color: white;
$popover-shadow-color: $pengine-grey-dark;

// == FigureList ==
$figure-size: 120px;
$figure-list-header-height: 32px;
$font-size-figure-list-header: 16px;

// == ReferenceSignList ==
$row-height: 32px;
$reference-sign-label-width: 128px;
$reference-sign-label-padding-left: 10px;
$reference-sign-name-padding-left: 10px;
$reference-sign-name-max-width: 160px;

// == Toolbar ==
$toolbar-min-height: 50px;

$dropdown-mobile-breakpoint: 0px;

// == Library management ==
$library-margin-bottom: 20px; 