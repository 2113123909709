
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.confirmation-dialog {
  .modal-dialog {
    &-dialog {

      .modal-dialog-body {
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: initial;
      }
    }

    &-header {
      h1 {
        margin: 0 auto;
      }
    }

    &-footer {
      padding-top: 0px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
