
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.menububble-container {
  overflow: visible;

  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 999;
    background-color: $popover-background-color;
    box-shadow: 3px 3px 6px $popover-shadow-color;
    padding: .3rem;
    margin-bottom: .5rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }
}
