
@import 'src/assets/styles/constants';

.library-management {
  margin: 0px 20px $library-margin-bottom 20px;
  $header-height: 32px;
  $filter-row-height: 45px;
  $width: 1280px;
  min-width: $width;

  .search-header {
    height: $header-height;
    width: $width;
    min-width: $width;
    margin: 0 auto;

    .b-tabs {
      margin-bottom: 0px;
      float: left;

      .tab-content {
        padding: 6px;
      }
    }

  }

  .b-table {
    width: $width;
    min-width: $width;
    margin: 4px auto 0px;
    padding-bottom: $footer-hight;
    height: calc(100% - #{$vertical-separator-input-padding-top} - 61px/* h1*/ - #{$header-height}/*search-header*/ - 10px /* unknown */
    );

    .filter-row {
      height: $filter-row-height;
      padding-top: 10px;
      position: relative;

      .field {
        min-width: 470px;
        max-width: 800px;
        float: left;
        margin-top: 0px;
        margin-bottom: 4px;
        $searchButtonSize: 27px;
        flex-direction: row-reverse;

        .taginput {
          margin-left: 6px;
          width: 100%;

          .taginput-container {
            min-height: 35px;
            padding-right: 14px !important;

            // The first tag should save some space for the search button on the left
            .tag:first-of-type {
              margin-left: $searchButtonSize;
            }

            .tag:not(body) {
              background-color: $pengine-blue-dark !important;
              color: white;

              a {
                &:before, &:after {
                  background-color: white;
                }
              }
            }

            .autocomplete {

              .control {
                input {
                  padding-left: 4px !important;
                  font-size: $font-size-input;
                }

                .icon {
                  width: 24px;
                  height: 24px;
                  font-size: 22px;
                }
              }
            }
          }

          // If there are no tags: let some space for the search button left to the input
          .taginput-container {
            .autocomplete {
              .control {
                input.is-empty {
                  padding-left: $searchButtonSize !important;
                }
              }
            }
          }
        }

        .search-button {
          height: 35px;
          margin-top: 0px;
          margin-right: -8px - $searchButtonSize;
          z-index: 5; // Stay in front of the input field (even on hover)
        }

        .delete-all-button {
          height: 35px;
          margin-top: 0px;
          margin-left: -26px;
          z-index: 5; // Stay in front of the input field (even on hover)
          .exi {
            height: 13px;
            width: 13px;
          }
        }
      }

      .filter {
        position: absolute;
        right: 0px;
        bottom: 0px;

        .control {
          float: right;
          margin-top: -28px;

          input.filter-input {
            width: inherit;
            min-width: 400px;
            max-width: 800px;
            height: 35px;
            font-size: $font-size-input;

            &:hover, &:focus {
              padding-left: 8px !important;
            }
          }
        }
      }
    }

    .add-button-row {
      .dropdown {
        float: right;
        margin-bottom: -3px;
        margin-right: 14px;
        padding-top: 12px;
        padding-bottom: 4px;
      }
    }

    .table-wrapper {
      position: inherit;
      min-width: $width;
      height: calc(100% - #{$filter-row-height} - 8px /* gap to filter-row*/
      ) !important;

      table {
        // Hack for Chrome and IE11 to make the clickable area 100% height.
        height: 1px;

        td {
          padding: 0px;
          text-align: left;

          .table-cell-link {
            width: 100%;
            height: 100%;
            padding: 6px 8px;
            cursor: pointer;
          }

          &.name-cell {
            height: 100%;
            max-width: 250px;
            overflow-wrap: break-word;
          }

          &.description-cell {
            max-width: 350px;
            overflow-wrap: break-word;
          }

          &.keyword-cell {

            .tags {
              margin-top: -1px;
              margin-bottom: -11px !important;

              .tag {
                background-color: $pengine-grey-dark !important;
                color: white;
              }
            }
          }

          &.button-cell {
            text-align: right;

            .table-cell-padding {
              padding-top: 8px;
              padding-right: 12px;
            }
          }
        }
      }
    }
  }
}
