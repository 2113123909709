
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

$mistake-info-width: 550px;

.mistake-info-wrapper {
  position: absolute;
  width: $mistake-info-width;
  margin-left: #{-$mistake-info-width / 2};
  overflow: visible;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  .mistake-info {
    position: absolute;
    margin-top: 5px;
    padding: 12px 0px 12px 0px;
    text-align: left;
    background-color: white;
    border-radius: 12px;
    z-index: 999;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);

    .headline {
      padding-left: 28px;
      font-size: $font-size-normal;
      font-weight: bold;

      &:before {
        background-color: $pengine-spellcheck-unknown;
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: 12px;
        top: 18px;
      }
    }

    .description {
      padding: 8px 12px 8px 12px;
      font-size: $font-size-input;

      i {
        height: 13px;
        width: 13px;
        margin-bottom: -1px;
      }
    }

    .replacement-list {
      padding-left: 8px;
      padding-right: 8px;
      width: $mistake-info-width;
      float: left;

      .replacement {
        float: left;
        margin: 2px;
        max-width: 100%;

        button {
          min-height: 26.6px;
          padding: 4px 6px;
          text-align: left;
        }
      }
    }

    .add-to-dictionary, .ignore {
      float: left;
      position: relative;
      margin-top: 4px;
      padding-top: 8px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      border-top: 1px solid $pengine-grey-light;

      button {
        span {
          display: flex;

          i {
            margin-right: 8px;
          }
        }

        &:hover:enabled {
          color: $pengine-blue-dark;
        }
      }
    }

    &.confused-words .headline:before {
      background-color: $pengine-spellcheck-confused-words;
    }

    &.punctuation .headline:before {
      background-color: $pengine-spellcheck-punctuation;
    }

    &.hilfestellung-kommasetzung .headline:before {
      background-color: $pengine-spellcheck-hilfestellung-kommasetzung;
    }

    &.grammar .headline:before {
      background-color: $pengine-spellcheck-grammar;
    }

    &.misc .headline:before {
      background-color: $pengine-spellcheck-misc;
    }

    &.semantics .headline:before {
      background-color: $pengine-spellcheck-semantics;
    }

    &.casing .headline:before {
      background-color: $pengine-spellcheck-casing;
    }

    &.compounding .headline:before {
      background-color: $pengine-spellcheck-compounding;
    }

    &.typography .headline:before {
      background-color: $pengine-spellcheck-typography;
    }

    &.typos .headline:before {
      background-color: $pengine-spellcheck-typos;
    }

    &.redundancy .headline:before {
      background-color: $pengine-spellcheck-redundancy;
    }

    &.correspondence .headline:before {
      background-color: $pengine-spellcheck-correspondence;
    }

    &.style .headline:before {
      background-color: $pengine-spellcheck-style;
    }

    &.colloquialisms .headline:before {
      background-color: $pengine-spellcheck-colloquialisms;
    }

    &.empfohlene-rechtschreibung .headline:before {
      background-color: $pengine-spellcheck-empfohlene-rechtschreibung;
    }
  }
}
