
@import '../../assets/styles/constants.scss';

.modal-dialog {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //has to be higher up than the header
  z-index: 50;
  align-items: center;
  display: flex;
  justify-content: center;

  &-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &-dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    font-size: $font-size-normal;
    display: flex;
    flex-direction: column;
    border-radius: $dialog-border-radius;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &-close {
    width: 30px;
    height: 30px;
    padding-right: 6px;
    padding-left: 6px;
  }

  &-header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .modal-dialog-close {
      margin-top: -12px;
      margin-right: -12px;

      .exi { // eXXcellent Icons
        height: 12px;
        width: 12px;
      }
    }
  }

  &-body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-footer {
    padding: 10px 20px 20px;
  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
