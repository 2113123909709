
.application {
  .application-main-splitpanes {
    > div:nth-child(2) {
      border-left: none !important;
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}
