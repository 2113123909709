@import './colors.scss';
// Color variables
@import './constants.scss';
// Reusable constants
@import '~bulma';
// Bulma Import
@import './icons.scss';
// eXXcellent / Nucleo UI Icons
@import './rotation.scss';
// Spinner class for loading indicators
@import './datepicker.scss';
// Global datepicker styling
@import './document.scss';
// Styling of document content
@import './symbols.scss'; // eXXcellent / Nucleo UI Symbols

html {
  height: 100%;
  overflow-y: hidden !important;

  body {
    height: 100%;
    color: $text-color;
    overflow: auto;

    .splitpanes__pane {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white !important;
    }

    .splitpanes__splitter {
      border-color: $pengine-grey-dark-dark !important;

      &:before, &:after {
        background-color: $pengine-grey-dark-dark !important;
      }
    }

    $splitter-thickness: 0.8px;

    // Overwrite globally for specific browsers
    .is-safari :global {
      $splitter-thickness: 1.0px;
    }

    .splitpanes--vertical .splitpanes__splitter {
      &:before, &:after {
        width: $splitter-thickness !important;
        height: 30px !important;
      }
    }

    .splitpanes--horizontal .splitpanes__splitter {
      &:before, &:after {
        width: 30px !important;
        height: $splitter-thickness !important;
      }
    }

    .vertical-separator {
      padding-top: 60px;
    }

    .vertical-separator-input {
      padding-top: $vertical-separator-input-padding-top;
    }

    .vertical-separator-half {
      padding-top: 30px;
    }

    .table {
      color: $text-color;
      border: 1px solid $pengine-grey-light !important;
      border-radius: $input-border-radius !important;

      .table-header {
        background-color: $pengine-grey-dark !important;
        border: none;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: normal;
        color: white;
      }

      tr:nth-child(even) {
        background-color: $pengine-grey-light;
      }

      .table-cell {
        border: none;
      }
    }

    a {
      color: $pengine-grey-dark-dark;
    }

    button {
      border: none;
      border-radius: 0px !important;
      box-shadow: none !important;
      outline: none;
      background-color: transparent;
      transition: all 0.3s ease 0s;
      cursor: pointer;
    }

    button:not(.icon-button) {
      background-color: $pengine-grey-light;
    }

    button.button-action {
      background-color: $pengine-blue-dark;
      color: white;

      .exi { // eXXcellent Icons
        background-color: white;
      }

      .exs { // eXXcellent Symbols
        background-color: white;
      }
    }

    button.button-action:focus {
      background-color: $pengine-blue;
    }

    button.button-delete {
      background-color: $pengine-delete-red;
      color: white;

      .exi { // eXXcellent Icons
        background-color: white;
      }

      .exs { // eXXcellent Symbols
        background-color: white;
      }
    }

    button.button-delete:focus {
      background-color: $pengine-delete-red-light;
    }

    .icon-button:focus {
      outline: 0;
    }

    button:hover:not(.icon-button):enabled {
      background-color: $pengine-grey;
    }

    button.button-action:hover:not(.icon-button):enabled {
      background-color: $pengine-blue-dark;
    }

    button.button-delete:hover:not(.icon-button):enabled {
      background-color: $pengine-delete-red-light !important;
    }

    button.button-cancel:focus, button.button-accept-choice:focus {
      background-color: $pengine-grey;
    }

    button:hover, button:focus {
      &:enabled:not(.button-action) {
        .exs { // eXXcellent Symbols
          background-color: $pengine-blue-dark;
        }
      }
    }


    button:hover {
      &:enabled:not(.button-action) {
        .exi { // eXXcellent Icons
          background-color: $pengine-blue-dark;
        }
      }
    }

    button:focus-visible {
      &:enabled:not(.button-action) {
        outline: 1px dashed gray;
      }
    }


    button:disabled {
      background-color: transparent;
      border: 1px solid $pengine-grey-light;
      cursor: not-allowed;
      color: $pengine-grey;

      &.icon-button {
        border: none;

        i {
          background-color: $pengine-grey;
        }
      }
    }

    button.button-action:not(.icon-button):disabled {
      .exi { // eXXcellent Icons
        background-color: $text-color;
      }

      .exs { // eXXcellent Symbols
        background-color: $text-color;
      }
    }

    .contains-text-icon {
      margin-top: 1px;
    }

    .text-icon {
      font-family: $editor-font;
      font-size: 21px;
      color: $pengine-grey-dark-dark;
    }

    .text-icon:hover, .text-icon:focus, .icon-button.is-active .text-icon {
      color: $pengine-blue-dark;
    }

    input, textarea, .textarea-grow-wrap::after, select, .taginput .taginput-container, .dropdown-trigger button:not(.icon-button) {
      padding-top: 2px;
      border: 1px solid $input-border-color !important;
      border-radius: $input-border-radius !important;
      color: $text-color;
      background-color: transparent;
      font: $input-font;
      box-shadow: none !important;
    }

    select {
      background-color: white; // for Firefox
    }

    input:disabled, textarea:disabled, select:disabled {
      border-color: $text-color-disabled !important; // box
      color: $text-color-disabled; // arrow
    }

    .dropdown-trigger, .dropdown-item {
      outline: none;
    }

    .dropdown-trigger button:not(.icon-button) {
      padding-top: 7px;
    }

    .dropdown-trigger button.icon-button {
      display: flex;
    }

    .taginput .taginput-container {
      padding-right: 3px !important;

      .autocomplete {

        input {
          border: none !important;
        }
      }
    }

    // Tags occure in tag inputs as well as in simple tag lists
    .tag {
      border-radius: 0px;
      font: $input-font;
      font-size: $font-size-input !important;

      background-color: $pengine-grey-light !important;
      color: $text-color;

      a {
        background-color: transparent;

        &:before, &:after {
          background-color: $text-color;
        }
      }
    }

    input, select {
      height: $input-height;
    }

    input:enabled:hover, textarea:enabled:hover, select:enabled:hover {
      border: 2px solid $input-border-hover-color !important;
      padding-left: 1px !important;
    }

    textarea:enabled:hover {
      margin-top: -1px;
    }

    .dropdown-trigger button:not(.icon-button):enabled:hover {
      border: 2px solid $input-border-hover-color !important;
      background-color: transparent;
    }

    input:disabled, textarea:disabled, select:disabled, .dropdown-trigger button:not(.icon-button):disabled {
      cursor: not-allowed;
    }

    input:focus, textarea:focus, select:focus {
      outline: none !important;
      border: 2px solid $input-border-focus-color !important;
      padding-left: 1px !important;
    }

    .dropdown-trigger button:not(.icon-button):focus {
      border: 2px solid $input-border-focus-color !important;
    }

    select:enabled:hover, select:focus {
      padding-top: 1px !important;
      padding-left: 0px !important;
      text-indent: -1px;
    }

    textarea:focus {
      margin-top: -1px;
    }

    select:focus {
      option:checked {
        background: $pengine-grey-light;
      }

      option:hover {
        background-color: $pengine-grey-light !important;
      }
    }

    // Invalid field
    .invalid {
      input, textarea, select,
      input:enabled:hover, textarea:enabled:hover, select:enabled:hover,
      input:focus, textarea:focus, select:focus {
        border-color: $error-color !important;
      }
    }

    // Fix: adjust selector for components of buefy-next
    input, textarea, select,
    input:enabled:hover, textarea:enabled:hover, select:enabled:hover,
    input:focus, textarea:focus, select:focus {
      &.invalid {
        border-color: $error-color !important;
      }
    }

    h1 {
      margin: revert;
      font-size: $font-size-h1;
      font-weight: bold;
    }

    h2 {
      font-size: $font-size-header;
    }

    .notices .toast {
      border-radius: $dialog-border-radius;
    }

    .b-tabs {
      .tabs {
        overflow-x: hidden;
        margin-bottom: 0px;

        ul, a {
          border-bottom-width: 2px;
        }

        li {
          &.is-active {
            a {
              border-bottom-color: $pengine-orange-dark-dark;
              color: $text-color;
            }

            :hover {
              background-color: unset;
            }
          }

          font-size: $font-size-tab;

          a {
            padding-bottom: 3px;
          }

          a:hover {
            background-color: $pengine-grey-light-light-hover;
          }
        }
      }
    }

    .scrollx {
      overflow-x: scroll;
    }

    // We use these instead of select boxes
    .dropdown-menu {
      padding-top: 0px;

      .dropdown-content {
        padding: 0px;
        border-radius: 0px;

        .dropdown-item {
          &.is-active {
            background-color: $pengine-blue;
          }
        }
      }
    }

    // Allows textareas to adapt to its content size. Must be in a div around the textarea.
    // The textarea itself needs this: onInput="this.parentNode.dataset.replicatedValue = this.value"
    .textarea-grow-wrap {
      // Easy way to plop the elements on top of each other and have them both sized based on the tallest one's height
      display: grid;

      &:after {
        // Note the weird space! Needed to preventy jumpy behavior
        content: attr(data-replicated-value) " ";

        // This is how textarea text behaves
        white-space: pre-wrap;

        // Hidden from view, clicks, and screen readers */
        visibility: hidden;
      }

      textarea {
        // You could leave this, but if a user resizes, it ruins the auto sizing
        resize: none;
      }

      textarea, &:after { // Identical styling required!!
        resize: none;
        cursor: text;

        // Place on top of each other
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    .table-empty-text {
      width: max-content;
      margin: 0 auto;

      :not(.is-loading) {
        color: $pengine-grey;
      }

      div {
        justify-content: center;
        text-align: center;

        .exi { // eXXcellent Icons
          margin-right: 6px;
        }

        .exs { // eXXcellent Symbols
          margin-right: 6px;
        }
      }
    }

    // Can be added to classes to deactivate their after pseudo element (needed for spellcheck widget)
    .no-after:after {
      content: none;
    }

    /* Sets the styling on buefy checkboxes */
    .b-checkbox.checkbox input[type="checkbox"]:checked + .check,
    .b-checkbox.checkbox input[type="checkbox"]:not(:disabled):checked + .check {
      background-color: $pengine-blue-dark;
      border-color: $pengine-blue-dark;
    }

    .b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check {
      -webkit-box-shadow: 0 0 0.5em rgba(0, 130, 200, 0.8) !important;
      box-shadow: 0 0 0.5em rgba(0, 130, 200, 0.8) !important;
    }

    .b-checkbox.checkbox input[type="checkbox"]:not(:disabled):hover + .check {
      border-color: $pengine-blue-dark;
    }

    .ai-generated:not(.reference-sign-theme-show reference-sign .ai-generated) {
      color: $pengine-ai-generated;

      strong {
        color: $pengine-ai-generated;
      }
    }
  }
}

.app-main {
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  overflow: auto;

  .router-view {
    min-width: 1280px;

    &:not(.with-pinned-header) {
      height: calc(100% - #{$header-height}) !important;
    }

    &.with-pinned-header {
      // The navbar menu doesn't have fixed height,
      // so it is with the height of its children
      // in this case - the search and "Rechtschreibung" buttons.
      // The nav-bar's height is not exactly a whole number so we have to round up 1px
      height: calc(100% - (#{$header-height} + #{$header-bar-nav-buttons-height} + 1px)) !important;
    }
  }

  // Special case library (to not display scrollbar if not needed)
  .library-management.router-view {
    &:not(.with-pinned-header) {
      height: calc(100% - #{$header-height + $library-margin-bottom}) !important;
    }
  }
}
