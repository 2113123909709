
@import 'src/assets/styles/constants';

.worklist {
  width: 100%;

  .cover-sheet {
    overflow: hidden;
  }

  .table-container {
    width: 1280px;
    min-width: 1280px;
    text-align: left;
    margin: 0 auto;
    padding-bottom: $footer-hight;
    height: calc(100% - #{$vertical-separator-input-padding-top} - 61px /* h1*/
    );

    .table-wrapper {
      position: relative; // Needed for buefy native loading blocker
      padding-left: 24px;
      padding-right: 24px;
      height: calc(100% - 28px /* height cover-sheet*/
      );

      .table {
        .table-cell {
          vertical-align: middle;

          .table-cell-link {
            width: 100%;
            height: 100%;
            padding: 6px 8px;
          }

          .table-cell-padding {
            display: flex;
            padding: 8px 12px;

            .icon-button {
              height: 20px;
            }
          }
        }
      }
    }
  }
}
