
@import 'src/assets/styles/colors';

.splash-screen {
  .modal-dialog {
    top: 0px;
    z-index: 1001; // Above header = 35; Above Error Toasts = 1001
    overflow-y: hidden;

    &-dialog {
      width: 100%;
      height: 100%;

      .modal-dialog-body {
        align-items: center;
        height: 100%;

        .patent-engine-logo {
          width: 300px;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        $flicker-speed: 2s;

        @keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-o-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-moz-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .animate-flicker {
          -webkit-animation: flickerAnimation $flicker-speed infinite;
          -moz-animation: flickerAnimation $flicker-speed infinite;
          -o-animation: flickerAnimation $flicker-speed infinite;
          animation: flickerAnimation $flicker-speed infinite;
        }
      }
    }
  }
}

