
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.dropdown.is-disabled {
  opacity: 1;
}

.download-docx-button {
  margin-left: 4px;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 20px;

  &:disabled {
    color: $text-color;
    background-color: transparent;
    opacity: 1;
  }
}

.loading-cursor {
  cursor: default;
}

.download-cursor {
  cursor: pointer;
}
