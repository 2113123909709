
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.b-sidebar {
  .sidebar-background {
    // If we activate the overlay we still want it to be transparent
    background: none !important;
  }

  .sidebar-content {
    margin-top: $header-height;
    background-color: white !important;

    .menu-list {
      li {
        ul {
          margin: 0;
          border-left: none;
        }
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .menu-item-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 16px;
          flex: 1;

          .expand-icon {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    :not(.inner-menu-item) a.is-active {
      background-color: $pengine-grey-light;
      color: $text-color;

      > .exi {
        background-color: $text-color;
      }
    }

    .inner-menu-item {
      a:hover.is-active {
        background-color: $pengine-grey-light-light;
      }

      a:not(:hover).is-active {
        background-color: white;
      }
    }
  }
}
