
@import 'src/assets/styles/constants';

.spellcheck-dictionary-management {
  margin: 0px 20px 0px 20px;
  $header-height: 32px;
  $filter-row-height: 45px;
  $width: 540px;
  $maxHeight: 500px;
  min-width: $width;

  .modal-dialog {

    &-dialog {
      overflow-x: auto !important;
      margin-top: 20px !important;

      .description {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    &-header {
      // width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    .b-table {
      width: $width;
      margin: 4px auto 0px;
      padding-bottom: 20px;

      .language-select-row {
        float: left;
        margin-top: -10px;
        margin-left: 1px;

        select {
          min-width: 86px;
          height: 30px;
          font-size: $font-size-normal;
          padding-left: 4px;

          &:enabled:hover, &:enabled:focus {
            padding-top: 2px !important;
            padding-left: 4px !important;
          }
        }
      }

      .add-button-row {
        float: right;
        margin-right: 14px;

        button:focus {
          background-color: $pengine-grey;
        }
      }

      button:disabled { // For add and delete buttons
        border: none;

        .exi {
          background-color: $text-color-disabled !important;;
        }
      }

      .delete-button:focus {
        .exi {
          background-color: red;

        }
      }

      .table-wrapper {
        position: inherit;
        min-width: $width;
        max-height: $maxHeight;

        &.has-sticky-header {
          height: inherit;
        }

        table {
          // Hack for Chrome and IE11 to make the clickable area 100% height.
          height: 1px;

          td {
            padding: 0px;
            text-align: left;

            input {
              margin-left: 4px;
              margin-right: 4px;
              margin-top: 3px;
              padding: 2px;
              height: 26px;
              width: calc(100% - 4px);
            }

            &.word-cell {
              height: 100%;
            }

            &.button-cell {
              text-align: right;

              .table-cell-padding {
                padding-top: 8px;
                padding-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}
