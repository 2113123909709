
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';

.applicationEditor {

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    flex-basis: auto;
  }

  .document-editor-area {
    border-right: 1px solid $pengine-grey;

    width: 100%;

    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    overflow: hidden;
  }
}

