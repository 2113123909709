@import './colors.scss';
@import './constants.scss';

.header {
  font-size: 120%;
  font-weight: bold;
  text-align: center;
}

.text-container {
  text-align: left;
}

p.block:not(.no-logical-block), div.block:not(.no-logical-block) {
  border: 1px solid transparent;
}

.current-logical-block *> .block.show-placeholder::after {
  content: attr(data-placeholder-text);
  color: gray;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.block.referenceSignListRow:not(:last-child), .block.REFERENCE_SIGN_LIST_ROW:not(:last-child) {
  margin-bottom: 8px !important;
}

.block.referenceSignListRow .block, .block.REFERENCE_SIGN_LIST_ROW .block {
  margin-bottom: 0px !important;
}

/**
 * Visualization Classes that determine the border color
 */
.parent-of-logical-block > div {
  border: 1px solid $pengine-grey-light !important;
}

.current-logical-block > div {
  border: 1px solid $pengine-orange-dark-dark !important;
}

p.current-logical-block {
  border: 1px solid $pengine-orange-dark-dark !important;
}

.sibling-of-logical-block > div {
  border: 1px solid $pengine-grey !important;
}

p.sibling-of-logical-block {
  border: 1px solid $pengine-grey !important;
}

/**
 * Visualization Classes that determine the padding and margin for every block-mode node that has a relation to an inline node
 */
.inline-sibling-top > div {
  margin-top: 12px !important;
}

.inline-sibling-bottom > div {
  margin-bottom: 12px !important;
}

.inline-child-top > div {
  padding-top: 12px !important;
}

.inline-child-bottom > div {
  padding-bottom: 12px !important;
}


/**
 * Visualization Classes that determine the blocks background color
 */
.selected-descendant-of-logical-block {
  background-color: white;
}

p.block-failed-to-update {
  border: 3px solid red !important;
}

.block-failed-to-update  > div {
  border: 3px solid red !important;
}

.odd-descendant-of-logical-block {
  background-color: $pengine-editor-odd-block;
}

.even-descendant-of-logical-block {
  background-color: $pengine-editor-even-block;
}

.CLAIM_CONTEXT {
  margin: 0px 0px 1em !important;
}

p.SHORT_DESCRIPTION_FIGURE {
  display: flex;
  flex-flow: row nowrap;

  span {
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: inherit;
    cursor: default;

    &.title {
      min-width: 50px;
      margin-right: 0.8cm;
    }

    &.description {
      // emtpy
    }
  }
}

p.REFERENCE_SIGN_LIST_ROW {
  display: flex;
  flex-flow: row nowrap;

  span {
    font-size: 16px !important;
    line-height: 1;
    font-weight: inherit;
    cursor: default;
    margin-bottom: 8px !important;

    &.title {
      min-width: 50px;
      margin-right: 0.8cm;
    }

    &.description {
      // emtpy
    }
  }
}

p.MAIN_CLAIM, p.PARALLEL_CLAIM p.SUB_CLAIM {
  display: block;
  //flex-flow: row nowrap;

  span {
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: inherit;
    cursor: default;

    &.title {
      min-width: 30px;
      margin-right: 0px;
    }

    &.description {
      // emtpy
    }
  }
}



.find {
  background-color: $pengine-editor-find !important;
}

.find-current {
  background-color: $pengine-editor-find-current !important;
}

.spelling-error {
  --color: #{$pengine-spellcheck-unknown};

  background-image: linear-gradient(45deg, transparent 65%, var(--color) 80%, transparent 90%),
  linear-gradient(135deg, transparent 5%, var(--color) 15%, transparent 25%),
  linear-gradient(135deg, transparent 45%, var(--color) 55%, transparent 65%),
  linear-gradient(45deg, transparent 25%, var(--color) 35%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 12px 3px;
  background-position-y: bottom;

  // hacky way to let the background image be drawn bellow the actual text
  margin-bottom: -2px !important;
  padding-bottom: +2px !important;

  &.confused-words {
    --color: #{$pengine-spellcheck-confused-words};
  }

  &.punctuation {
    --color: #{$pengine-spellcheck-punctuation};
  }

  &.hilfestellung-kommasetzung {
    --color: #{$pengine-spellcheck-hilfestellung-kommasetzung};
  }

  &.grammar {
    --color: #{$pengine-spellcheck-grammar};
  }

  &.misc {
    --color: #{$pengine-spellcheck-misc};
  }

  &.semantics {
    --color: #{$pengine-spellcheck-semantics};
  }

  &.casing {
    --color: #{$pengine-spellcheck-casing};
  }

  &.compounding {
    --color: #{$pengine-spellcheck-compounding};
  }

  &.typography {
    --color: #{$pengine-spellcheck-typography};
  }

  &.typos {
    --color: #{$pengine-spellcheck-typos};
  }

  &.redundancy {
    --color: #{$pengine-spellcheck-redundancy};
  }

  &.correspondence {
    --color: #{$pengine-spellcheck-correspondence};
  }

  &.style {
    --color: #{$pengine-spellcheck-style};
  }

  &.colloquialisms {
    --color: #{$pengine-spellcheck-colloquialisms};
  }

  &.empfohlene-rechtschreibung {
    --color: #{$pengine-spellcheck-empfohlene-rechtschreibung};
  }
}

.ProseMirror:not(.ProseMirror-focused) {
  .mistake-info-wrapper {
    display: none !important;
  }
}

