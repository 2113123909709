
@import "src/assets/styles/colors";

div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
}

.history-block {
  .exi {
    margin: 0 !important;
  }

  // Remove button border in any case
  button, button.dropdown-button {
    border: 0px solid transparent !important;

    &:hover:enabled, &:focus:enabled {
      border: 0px solid transparent !important;
    }
  }

  // Styling for normal buttons
  button:not(.dropdown-button) {
    padding-right: 0px !important;
  }

  // Styling for dropdown buttons
  .dropdown-button {
    padding: 0 4px;
    height: 35px;

    .exi {
      width: 8px !important;
      height: 8px !important;
    }
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: normal;
    padding: 1px 8px;

    display: block;
    text-align: start;
    vertical-align: middle;
    line-height: 18px;
    min-height: 20px;

    width: 550px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .highlight {
    background-color: whitesmoke;
    color: #0a0a0a;
  }

  .container-placeholder {
    width: 42px;
  }
}

