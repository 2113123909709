
@import 'src/assets/styles/constants';

.reference-sign-list {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: visible;
  text-align: left;
  width: 100%;

  .reference-sign-header {
    border-bottom: 1px solid $pengine-grey;
    display: flex;
    flex-flow: row nowrap;

    .left-aligned-stuff {
      -webkit-user-select: none;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: $subheader-height;
      min-height: $subheader-height;
      padding-left: 5px;
      user-select: none;
      width: inherit;

      h2 {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 12px;
        width: max-content;
      }

      button {
        margin-left: 10px;
        margin-top: 0;

        &:disabled {
          border: none;
        }
      }
    }

    .right-aligned-stuff {
      display: flex;
      flex-flow: row-reverse nowrap;
      width: 100%;

      .toggle-show-reference-signs-button {
        i {
          height: 2em;
          width: 2em;

          &.active {
            background-color: $pengine-reference-sign;
          }

          &:hover {
            background-color: $pengine-blue-dark;
          }
        }
      }

      .create-button {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  .table-container {
    font-size: $font-size-normal;
    height: inherit;
    overflow: visible;
    overflow-y: auto;
    width: 100%;

    :deep(.b-table) {
      .table-wrapper {
        .table {
          border: transparent !important;

          .button-container {
            height: $row-height;
            float: right;

            .hover-button {
              visibility: hidden;
              transition: none;
            }

            .button {
              height: $row-height;
              background-color: transparent;
              border: none;
              padding: 0px 10px 0px 10px;
            }

            .exi-small-spinner-unmasked {
              margin-right: 10px;
            }
          }

          tr:hover .hover-button {
            visibility: visible !important;
          }


          tr {
            background-color: transparent;
            height: $row-height;

            td {
              border-bottom: none;
              border-top: none;
              padding: 0;
              vertical-align: middle;

              .no-matches {
                color: $pengine-document-structure-tree-empty-block;
              }

              .cell-text-wrapper {
                align-items: center;
                display: flex;
                flex-flow: row nowrap;

                .cell-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .no-matches {
                  color: $pengine-document-structure-tree-empty-block;
                }

                .warning-sign-wrapper {
                  height: 18px;
                  margin: 0 5px;
                  width: 18px;

                  i {
                    background-color: $pengine-warn-yellow;
                  }
                }
              }

              // Both input types
              input {
                color: $text-color;
                font-family: $text-font;
                font-size: $font-size-normal;
                height: 23px !important;
                padding: 1px 4px 0px 4px;
              }

              // Label input
              div:has(.label-input) {
                input:enabled {
                  &:hover, &:focus {
                    padding-left: 4px !important;
                  }
                }
              }

              div:has(.name-input) {
                padding-left: 3px;
              }

              // Name input
              div:has(:not(.label-input)) {
                input:enabled {
                  &:hover, &:focus {
                    padding-left: 3px !important;
                  }
                }
              }

              .counter {
              }

              &:first-child {
                .input {
                  text-align: center;
                }

                padding-left: $reference-sign-label-padding-left;
                width: $reference-sign-label-width;
              }

              &:nth-child(2) {
                max-width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &:nth-child(3) {
                white-space: nowrap;
                width: 80px; // Fixed width for the right button container
              }
            }
          }
        }
      }
    }
  }
}
