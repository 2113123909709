
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';

// Suppress Chrome displaying a visible outline when focussing the element
:focus-visible {
  outline: none;
}

.figureEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: fit-content;
    border-bottom: 1px solid $pengine-grey;
    max-height: 54px;
    position: relative;

    .menubar {
      background-color: white;
    }
  }

  .content {
    width: 100%;
    flex-grow: 1;
    background-color: $pengine-grey;
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    overflow-y: hidden;
  }
}
