

.generate-container {
  display: flex;
  flex-flow: row-reverse wrap;
  text-align: right;

  button:disabled {
    background-color: transparent;
    border-color: transparent;
  }

  button:focus-visible {
    color: black;
  }
}

