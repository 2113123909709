
@import 'src/assets/styles/colors';

#figureEditorToolbar {
  display: flex;
  justify-content: start;
  // min-width: 700px;
  padding: 8px 0px 8px 8px;

  i {
    margin-right: 0;
  }

  button {
    padding: 6px 6px;
  }

  /* toolbar containing zoom controls pushed to the left. */
  .zoom-menubar {
    top: 8px;
    left: 8px;
    display: flex;
  }

  .fig-number-div {
    //flex-grow: 1;
    max-width: 30em;
    text-align: left;
  }

  .metadata-menu {
    display: flex;

    .scaling-menu {

      .toolbar-text {
        white-space: nowrap;
      }
    }
  }

  .breaking-items {
    display: flex;
    width: 100%;
    flex-flow: row wrap;

    .main-menubar {
      display: flex;
      flex-grow: 1;
      justify-content: center;

      .delete-button {

        .exi-delete {
          margin-right: 8px;
        }
      }
    }
  }

  .seperator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }

  .scaling-menu {
    padding-left: 8px;
    padding-top: 4px;
    display: flex;

    .toolbar-text {
      margin-right: 4px;
    }

    .toolbar-text.disabled {
      color: $pengine-grey;
    }
  }

  .scaling-input {
    text-align: right;
    margin-top: 5px;
    margin-left: 2px;
    margin-right: 2px;
    width: 50px;
    padding: 0;
  }

  .devider {
    background-color: $pengine-grey;
    width: 1px;
  }

  .stepper {
    min-width: 90px;
    margin-top: 2px;

    i {
      margin: 0;
    }

    .figureStepper {
      text-align: center;
      -moz-appearance: textfield;
      width: 25px;
    }

    .figureStepper::-webkit-outer-spin-button,
    .figureStepper::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button, button:focus, button:hover {
    background-color: transparent !important;
    color: $pengine-grey-dark-dark !important;
  }

  button.delete-button:hover {
    color: $pengine-delete-red !important;
  }

  button:not(:disabled).icon-button:hover {
    color: $pengine-blue-dark !important;
  }

  button:not(:disabled) {
    i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }

    span {
      &.is-active {
        color: $pengine-blue-dark;
      }

      &:hover {
        color: $pengine-blue-dark;
      }
    }
  }

  .button:disabled {
    &.is-info {
      background-color: #3e8ed0;
      border-color: transparent;
      box-shadow: none;
    }

    &.icon-button {
      i {
        background-color: $pengine-grey-dark-dark;
      }
    }
  }

  .button.is-info[disabled] {
    background-color: #3e8ed0;
    border-color: transparent;
    box-shadow: none;
  }

  .stepper-button {
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .seperator {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .toolbar-text {
    font-weight: normal;
    font-size: 14px;
    color: $pengine-grey-dark-dark;
    height: 24px;
  }

  .toggle {
    font-size: 14px;
    padding-top: 16px;
    height: 24px;
  }

  .selected {
    color: $pengine-blue-dark !important;

    &:hover, &:focus {
      color: $pengine-blue-dark !important;
    }
  }
}
