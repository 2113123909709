
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.search-result-dialog {
  .modal-dialog {
    $dialog-width: 780px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      h1 {
        margin: 0 auto;
        font-size: $font-size-h1;
        font-weight: normal;
        color: $text-color;
        line-height: normal;
        cursor: default;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-body {
      $field-height: 30px;
      $select-width: 184px;
      padding: 10px 32px 10px;
      min-height: 240px;
      // max-height: 560px;
      // overflow: hidden;
      margin-bottom: 20px;

      h2 {
        cursor: default;
      }

      label, input, textarea, select {
        font-size: $font-size-modal !important;
        text-align: start;
        margin-top: -((36 - $field-height)/2);
      }

      select {
        width: $select-width;
        height: $field-height;
      }

      input::placeholder, select::placeholder, textarea::placeholder {
        color: $pengine-grey;
      }

      input.tag-input {
        height: 24px !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }

      .column {
        float: left;
        padding-left: 0px;

        margin-bottom: 10px;

        &:first-of-type {
          margin-top: 5px;
        }

        input.name-input {
          width: 400px;
          height: $field-height;
          padding: 4px;
          font-size: $font-size-input;

          &:hover, &:focus {
            padding-left: 3px !important;
          }
        }
      }

      .keywords {
        text-align: left !important;
        margin-bottom: 10px;

        label {
          .exi { // eXXcellent Icons
            display: inline-block;
            // padding-top: 2px;
            margin-bottom: -3px;
          }
        }
      }

      .taginput {
        margin-top: 5px;

        .taginput-container {
          padding-top: 5px;
          padding-bottom: 5px;

          .tag:not(body) {
            background-color: $pengine-blue-dark !important;
            color: white;
            margin-bottom: 1px;

            a {
              &:before, &:after {
                background-color: white;
              }
            }
          }

          .autocomplete {
            padding-left: 0px;

            .control {
              input {
                padding-left: 24px !important;
              }

              .icon {
                width: 24px;
                height: 24px;
                font-size: 22px;
              }
            }
          }
        }
      }

      .taginput input {
        &:hover, &:focus {
          padding-left: 8px !important;
        }
      }
    }

    &-footer {
      padding-top: 0px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
