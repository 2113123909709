

.block-mode {
  display: inline-block;
  box-sizing: content-box !important; // important for the width sizing of the div's
  padding-right: 12px;
}

.block-mode:not(.last-block) {
  margin-bottom: 17px;
}

.block-mode > div {
  margin-bottom: 2px;
  margin-top: 2px;
}

