
.user-icon-container {
  padding-right: 16px;
  align-self: center;

  .exi-face { // eXXcellent Icons
    height: 48px;
    width: 48px;
  }
}
