
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';


.content {
  position: relative;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  margin-bottom: 0px;
  border: 2px solid transparent;

  &.active-editor-view {
    border: 2px solid $pengine-blue-dark;
  }

  background-color: $pengine-grey;

  .spinner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .editor {
    padding: 80px;
    background-color: white;
    width: 900px;
    color: black;
  }

  .ai-button {
    border: none;
    background-color: #FFFFFF;
    position: absolute;
    align-content: center;
    width: 27px;
    height: 27px;
    z-index: 10;
    transition: all 0.3s ease 0s;
  }

  .ai-button-inner {
    width: 27px;
    height: 27px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;

    &:hover {
      background-color: #FFFFFF;
    }
  }

  .ai-button-icon {
    background-color: $pengine-orange-dark-dark;
    width: 27px;
    height: 27px;

    .i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }
  }

  .container {
    position: relative;
    text-align: center;
  }

  .centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button:not(:disabled) {
    i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }

    span {
      &.is-active {
        color: $pengine-blue-dark;
      }

      &:hover {
        color: $pengine-blue-dark;
      }
    }
  }
}
